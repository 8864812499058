import Spinner from '@ui/Spinner';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

type PhoneOTPProps = {
  codeOtpLoading: boolean;
  otpDigits: string[];
  setOtpDigits: (digits: string[]) => void;
  submitConfirmationCode: (
    code: string[],
    i: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  timeToResend: number;
  setTimeToResend: (time: number) => void;
  sendConfirmationCode: () => void;
  sendingCode: boolean;
};

export default function PhoneOTP({
  codeOtpLoading,
  otpDigits,
  setOtpDigits,
  submitConfirmationCode,
  timeToResend,
  setTimeToResend,
  sendConfirmationCode,
  sendingCode,
}: PhoneOTPProps) {
  const allDigitsFilled =
    otpDigits.length === 6 &&
    otpDigits.every((digit) => digit !== undefined && digit !== '');

  const expirationTime = useRef<number>(0);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timeToResend > 0 && !expirationTime.current) {
      expirationTime.current = Date.now() + timeToResend * 1000;
    }

    const updateTimer = () => {
      if (expirationTime.current) {
        const now = Date.now();
        const remaining = Math.max(
          0,
          Math.floor((expirationTime.current - now) / 1000)
        );

        if (remaining !== timeToResend) {
          setTimeToResend(remaining);
        }

        if (remaining <= 0) {
          expirationTime.current = 0;
          clearInterval(timerRef.current);
        }
      }
    };

    timerRef.current = setInterval(updateTimer, 1000);
    updateTimer(); // Initial call to avoid delay

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [timeToResend, setTimeToResend]);

  const handleResend = () => {
    if (!sendingCode) {
      sendConfirmationCode();
      expirationTime.current = Date.now() + timeToResend * 1000;
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="mt-8 flex items-center gap-x-8"
      >
        {Array(6)
          .fill(0)
          .map((_, i) => (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.1 * i }}
              className="flex w-[8rem] flex-col items-center"
              key={`otp-${i}`}
            >
              <div className="flex w-full flex-col items-center">
                <input
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={1}
                  name={`otp-${i}`}
                  id={`otp-${i}`}
                  className={`w-full border-none bg-transparent text-center text-[3rem] font-bold text-black outline-none ${
                    codeOtpLoading || allDigitsFilled
                      ? 'animate-pulse cursor-not-allowed opacity-50'
                      : ''
                  }`}
                  disabled={codeOtpLoading}
                  value={otpDigits[i] || ''}
                  onChange={(e) => {
                    const value = e.target.value.slice(-1);

                    if (value && !/^\d+$/.test(value)) {
                      return;
                    }

                    const newDigits = [...otpDigits];
                    newDigits[i] = value;
                    setOtpDigits(newDigits);

                    if (value) {
                      document.getElementById(`otp-${i + 1}`)?.focus();

                      const allDigitsAreValid = newDigits.every(
                        (digit) => digit !== undefined
                      );

                      if (i === 5 && allDigitsAreValid) {
                        submitConfirmationCode(newDigits, i, e);
                      }
                    }
                  }}
                  onPaste={(e) => {
                    if (i === 0) {
                      e.preventDefault();
                      const pastedData = e.clipboardData
                        .getData('text')
                        .replace(/\s+/g, '');
                      if (!/^\d+$/.test(pastedData)) {
                        return;
                      }

                      const digits = pastedData.slice(0, 6).split('');

                      if (digits.length) {
                        setOtpDigits(digits);

                        const lastFilledIndex = Math.min(5, digits.length - 1);
                        document
                          .getElementById(`otp-${lastFilledIndex}`)
                          ?.focus();

                        if (digits.length === 6) {
                          submitConfirmationCode(digits, 5, e as any);
                        }
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace' && !otpDigits[i]) {
                      document.getElementById(`otp-${i - 1}`)?.focus();
                      setOtpDigits([...otpDigits].slice(0, i - 1));
                    }
                  }}
                />

                <div
                  className={`h-px w-full ${
                    codeOtpLoading || allDigitsFilled
                      ? 'animate-pulse bg-brand-secondary opacity-50'
                      : otpDigits[i] === undefined
                      ? 'bg-brand-light-gray'
                      : 'bg-brand-secondary'
                  }`}
                />
              </div>
            </motion.div>
          ))}
      </motion.div>
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
        className="mt-16 text-[1.8rem] font-medium text-[#444444] sm:mt-8"
      >
        Didn&apos;t get a code?{' '}
        {timeToResend > 0 ? (
          <span className="font-semibold text-zinc-500">
            Ask for a new code in {timeToResend}s
          </span>
        ) : (
          <button
            type="button"
            className="cursor-pointer font-semibold text-brand-secondary disabled:cursor-not-allowed disabled:opacity-50"
            onClick={handleResend}
            disabled={sendingCode}
          >
            Resend
          </button>
        )}
      </motion.span>

      {codeOtpLoading && (
        <div className="flex w-full justify-center py-8">
          <Spinner className="h-16 w-16" />
        </div>
      )}
    </>
  );
}
