import FormLabel from '@c/forms/controls/FormLabel';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  createUserDocument,
  getUserByPhone,
  getValidUserDocument,
  updateUserByKey,
} from '@util/firestore/users';
import { logError } from '@util/logError';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '..';
import { LoginCountryDropdown } from '@c/forms/CountryDropdown';
import { dialCountries } from 'features/contryDropdown/lib/countries';
import PhoneOTP from 'features/phoneOTP';
import { usePhoneOTP } from 'features/phoneOTP/hooks/usePhoneOTP';
import { motion } from 'framer-motion';
import {
  linkWithCredential,
  PhoneAuthProvider,
  signInWithCredential,
} from 'firebase/auth';
import { AUTH } from '@util/firebase';
import { FirebaseError } from 'firebase/app';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

function PhoneForm({
  phone: initialPhone,
  verificationId: initialVerificationId,
  continueFn,
  title = 'Enter Phone Number',
  countryCode = 'US',
  setCountryCode,
  setCreatedUserDoc,
  attribution,
}: {
  phone: string;
  verificationId: string;
  continueFn: () => void;
  title?: string;
  countryCode?: string;
  setCountryCode?: (code: string) => void;
  setCreatedUserDoc?: Dispatch<
    SetStateAction<{
      uid: string;
      name: string;
      isSocial: boolean;
      phone: string;
      smsConsent: boolean;
    }>
  >;
  attribution?:
    | {
        source: string;
        medium: string | null;
        campaign: string | null;
        term: string | null;
        content: string | null;
        id: string | null;
        fbclid: string | null;
      }
    | null
    | undefined;
}) {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(initialPhone);
  const [verificationId, setVerificationId] = useState(initialVerificationId);
  const { linkPhone, sendPhoneVerification, user } = useAuth();
  const { showSuccessToast, showErrorToast } = useToastContext();
  const [sendingCode, setSendingCode] = useState(false);
  const [localCountryCode, setLocalCountryCode] = useState(countryCode);
  const [authError, setAuthError] = useState<Error | null>(null);
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    const savedCountryCode = localStorage.getItem('mxlocker_country_code');
    if (savedCountryCode) {
      setLocalCountryCode(savedCountryCode);
      if (setCountryCode) {
        setCountryCode(savedCountryCode);
      }
    }
  }, [setCountryCode]);

  const handleCountryCodeChange = (code: string) => {
    localStorage.setItem('mxlocker_country_code', code);
    setLocalCountryCode(code);
    if (setCountryCode) {
      setCountryCode(code);
    }
  };

  const form = useForm<{ code: string }>({
    defaultValues: {
      code: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(
      z.object({ code: z.string().length(6, 'Code is six digits') })
    ),
  });

  const { otpDigits, setOtpDigits, timeToResend, setTimeToResend } =
    usePhoneOTP();

  const formatPhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/[\s-]/g, '');
  };

  async function onSubmit(form: { code: string }) {
    if (searchParams?.get('step')) {
      if (AUTH.currentUser) {
        setLoading(true);

        try {
          await linkPhone(verificationId, form.code);
        } catch (error) {
          setLoading(false);
          if (error instanceof FirebaseError) {
            if (
              error.code === 'auth/credential-already-in-use' ||
              error.code === 'auth/account-exists-with-different-credential'
            ) {
              setAuthError(new Error('Phone number already in use'));
            } else {
              setAuthError(error as Error);
            }
          } else {
            logError(error);
            setAuthError(new Error('An error occurred. Please try again.'));
          }
          return;
        }

        continueFn();
      }
    } else {
      try {
        const existentUser = await getUserByPhone(`${countryDialCode}${phone}`);

        if (existentUser) {
          setAuthError(new Error('Phone number already in use.'));
          setLoading(false);
          return;
        } else {
          setLoading(true);

          const credential = PhoneAuthProvider.credential(
            verificationId,
            form.code
          );

          if (
            pathname === '/verify-phone' ||
            pathname?.startsWith('/order-confirmation')
          ) {
            if (!AUTH.currentUser) {
              router.push('/login');
            } else {
              await linkWithCredential(AUTH.currentUser, credential);
            }
          } else {
            const res = await signInWithCredential(AUTH, credential);

            const validUserDoc = getValidUserDocument(
              res.user.uid,
              res.user.phoneNumber ?? '',
              {
                name: res.user.displayName?.split(' ')?.[0] ?? '',
                opted_out: false,
              },
              attribution ?? null
            );

            await createUserDocument(validUserDoc, 'phone');

            if (!res.user || !res.user.uid) {
              throw new Error(
                'Failed to get user information after authentication'
              );
            }

            const createdUserDoc = {
              uid: res.user.uid,
              name: res.user.displayName?.split(' ')?.[0] ?? '',
              isSocial: false,
              phone: res.user.phoneNumber ?? '',
              isPhoneVerified: true,
              smsConsent: false,
            };

            setCreatedUserDoc?.(createdUserDoc);

            if (res.user.phoneNumber) {
              try {
                await updateUserByKey(
                  res.user.uid,
                  'phone',
                  res.user.phoneNumber || ''
                );
              } catch (updateError) {
                logError(
                  updateError,
                  `verify-phone onSubmit updateUserByKey - ${user?.uid}`
                );
              }
            }
          }

          showSuccessToast('Phone number verified!');

          continueFn();
        }
      } catch (e) {
        if (e instanceof FirebaseError) {
          if (e.code === 'auth/invalid-verification-code') {
            setAuthError(new Error('Invalid verification code'));
            setLoading(false);
            setOtpDigits(Array(6).fill(''));
          } else {
            setAuthError(e);
          }

          if (e.code === 'auth/phone')
            showErrorToast(e.message, 'bottom-center');
          logError(e, `verify-phone onSubmit - ${user?.uid || ''}`);
        } else {
          // TODO: remove this after we have proper error handling
          setAuthError(new Error(e as string));
          showErrorToast((e as Error).message, 'bottom-center');
          logError(e, `verify-phone onSubmit - ${user?.uid || ''}`);
        }
      } finally {
        setLoading(false);
      }
    }
  }

  const countryDialCode = dialCountries.find(
    (c) => c.code === localCountryCode
  )?.dial_code;

  const displayPhoneNumber = phone.startsWith('+')
    ? phone
    : `${countryDialCode}${phone}`;

  const sendCode = () => {
    let phoneWithCountry = formatPhoneNumber(phone);

    if (!phoneWithCountry.startsWith('+')) {
      phoneWithCountry = `+${countryDialCode?.replace(
        '+',
        ''
      )}${phoneWithCountry}`;
    }
    const parsed = parsePhoneNumber(phoneWithCountry);
    if (!parsed) {
      alert('Unable to parse phone number. Please try again.');
      return;
    }
    sendPhoneVerification(parsed.format('E.164'))
      .then((verificationId) => {
        form.setValue('code', '');
        setVerificationId(verificationId);
      })
      .catch((e) => {
        logError(e, `verify-phone resend - ${user?.uid}`);
        setAuthError(
          new Error(
            'Unable to send verification code. Please refresh the page and try again or contact support at support@mxlocker.com.'
          )
        );
      });
  };

  return (
    <div className="mx-auto flex w-full max-w-[36rem] flex-col gap-[1.6rem] sm:max-w-none">
      <div className="relative gap-[2rem]">
        <form
          className="flex flex-col gap-[4rem]"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {phone && verificationId ? (
            <>
              <div className="flex flex-col gap-y-2">
                <h1 className="text-[3rem] font-semibold">
                  Enter your verification code
                </h1>
                <div className="flex items-center gap-x-4">
                  <h2 className="text-[1.8rem] text-[#444444]">
                    SMS code was sent to your phone{' '}
                    {formatPhoneNumber(displayPhoneNumber)}
                  </h2>
                  <button
                    onClick={() => {
                      const countryDialCode = dialCountries.find(
                        (c) => c.code === localCountryCode
                      )?.dial_code;
                      const phoneWithoutDialCode = phone.startsWith(
                        countryDialCode || ''
                      )
                        ? phone.slice(countryDialCode?.length)
                        : phone;

                      // removing the dial code from the phone number, since it will be set in the country selector dropdown
                      setPhone(phoneWithoutDialCode);
                      setVerificationId('');
                      setLoading(false);
                    }}
                    className="text-[1.8rem] font-semibold text-brand-secondary hover:text-brand-primary"
                  >
                    Edit
                  </button>
                </div>

                {authError && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                    className="mt-8 flex flex-col"
                  >
                    <span className="tracking-widest text-zinc-500">ERROR</span>

                    <span className="max-w-[40rem] text-[1.8rem] font-medium text-brand-secondary">
                      {authError.message}
                    </span>
                  </motion.div>
                )}
              </div>

              <PhoneOTP
                codeOtpLoading={loading}
                otpDigits={otpDigits}
                setOtpDigits={setOtpDigits}
                submitConfirmationCode={(code) =>
                  onSubmit({ code: code.join('') })
                }
                timeToResend={timeToResend}
                setTimeToResend={setTimeToResend}
                sendConfirmationCode={sendCode}
                sendingCode={sendingCode}
              />
            </>
          ) : (
            <>
              <h1 className="text-[2rem] font-semibold sm:text-[3rem]">
                {title}
              </h1>

              <FormLabel required value="Phone Number">
                <LoginCountryDropdown
                  value={phone}
                  autoComplete="tel-national"
                  onChange={(e) => setPhone(e)}
                  onCountryChange={(code) => handleCountryCodeChange(code)}
                  countryCode={localCountryCode}
                />
              </FormLabel>
              <h2 className="text-[1.6rem]">
                We will send a verification code to this number
              </h2>
              <Button
                text="Send Code"
                loading={loading}
                type="secondary"
                className="!text-[1.8rem]"
                onClick={() => {
                  const formattedPhone = formatPhoneNumber(phone);
                  const phoneNumber = `+${countryDialCode?.replace(
                    '+',
                    ''
                  )}${formattedPhone}`;

                  const parsed = parsePhoneNumber(phoneNumber);

                  if (!parsed) {
                    alert('Unable to parse phone number. Please try again.');
                    return;
                  }
                  setLoading(true);
                  sendPhoneVerification(parsed.format('E.164'))
                    .then((verificationId) => {
                      setVerificationId(verificationId);
                    })
                    .catch((e) => {
                      if ((e as Error).message.includes('TOO_SHORT')) {
                        setAuthError(
                          new Error(
                            'Phone number is too short. Please try again.'
                          )
                        );
                        return;
                      }
                      if ((e as Error).message.includes('ALREADY_IN_USE')) {
                        setAuthError(
                          new Error(
                            'Phone number is already in use. Please log in instead.'
                          )
                        );
                        return;
                      }
                      logError(e, `verify-phone sendCode - ${user?.uid}`);
                      setAuthError(
                        new Error(
                          'Unable to send verification code. Please refresh the page and try again or contact support at support@mxlocker.com.'
                        )
                      );
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }}
              />
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default PhoneForm;
