import Select from '@ui/Select';
import { dialCountries } from 'features/contryDropdown/lib/countries';
import { ChevronDownIcon } from 'lucide-react';
import Image from 'next/image';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export const CountryDropdown = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (value: string) => void;
}) => {
  useEffect(() => {
    localStorage.setItem('mxlocker_country_code', value);
  }, [value]);

  return (
    <div className="w-[20rem]">
      <Select
        allowEmpty={false}
        value={value}
        onChange={(e) => setValue(e.value)}
        options={dialCountries
          .sort((a, b) => {
            const aCode = parseInt(a.dial_code.replace('+', ''));
            const bCode = parseInt(b.dial_code.replace('+', ''));
            return aCode - bCode;
          })
          .map((val) => ({
            label: val.flag + ' ' + val.dial_code,
            value: val.code,
            id: val.code,
          }))}
      />
    </div>
  );
};

type LoginCountryDropdownProps = {
  value: string;
  autoComplete: string;
  onChange: (value: string) => void;
  onCountryChange: (value: string) => void;
  countryCode: string;
};

export const LoginCountryDropdown = ({
  autoComplete,
  value,
  onChange,
  onCountryChange,
  countryCode,
}: LoginCountryDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const selectedCountry =
    dialCountries.find((c) => c.code === countryCode) || dialCountries[0];

  const filteredCountries = dialCountries
    .sort((a, b) => {
      const aExactNameMatch =
        a.name.toLowerCase() === searchQuery.toLowerCase();
      const bExactNameMatch =
        b.name.toLowerCase() === searchQuery.toLowerCase();
      const aExactDialMatch = a.dial_code === searchQuery;
      const bExactDialMatch = b.dial_code === searchQuery;

      if (aExactNameMatch && !bExactNameMatch) return -1;
      if (!aExactNameMatch && bExactNameMatch) return 1;
      if (aExactDialMatch && !bExactDialMatch) return -1;
      if (!aExactDialMatch && bExactDialMatch) return 1;

      return 0;
    })
    .filter(
      (country) =>
        country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        country.dial_code.includes(searchQuery)
    );

  useEffect(() => {
    localStorage.setItem('mxlocker_country_code', countryCode);
  }, [countryCode]);

  return (
    <div className="flex h-[4rem] w-full items-end gap-x-6">
      <div className="relative">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="flex h-full w-[12rem] items-center justify-between gap-x-4 border-b border-[#D9D9D9] pb-2"
        >
          <div className="flex items-center gap-x-4">
            <Image
              className="aspect-square h-[2.5rem] w-auto rounded-full object-cover"
              alt={selectedCountry.name}
              src={`https://flagcdn.com/${selectedCountry.code.toLowerCase()}.svg`}
              width={200}
              height={200}
            />

            <span className="text-[2.1rem] font-bold text-black">
              {selectedCountry.dial_code}
            </span>
          </div>

          <ChevronDownIcon className="h-8 w-8 text-black" />
        </button>

        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            className="absolute left-0 top-full z-50 max-h-[30rem] w-[30rem] overflow-y-auto rounded-[1rem] border border-gray-200 bg-white shadow-lg"
          >
            <div className="sticky top-0 bg-white p-2">
              <input
                type="text"
                placeholder="Search country or dial code..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full rounded-md border-b border-zinc-200 p-2 text-[1.6rem] outline-none"
                autoFocus
              />
            </div>
            {filteredCountries.map((country) => (
              <button
                key={country.code}
                onClick={() => {
                  setIsOpen(false);
                  setSearchQuery('');
                  onCountryChange(country.code);
                  localStorage.setItem('mxlocker_country_code', country.code);
                }}
                className="flex w-full items-center gap-x-4 px-4 py-3 hover:bg-gray-50"
              >
                <Image
                  className="aspect-square h-[2.5rem] w-auto rounded-full object-cover"
                  alt={country.name}
                  src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                  width={200}
                  height={200}
                />
                <span className="whitespace-nowrap text-left text-[1.6rem]">
                  {country.name}
                </span>
                <span className="ml-auto text-[1.6rem] font-bold">
                  {country.dial_code}
                </span>
              </button>
            ))}
          </motion.div>
        )}
      </div>

      <div className="flex h-full w-full flex-col items-center">
        <input
          type="number"
          name="tel"
          id="tel"
          autoComplete={autoComplete}
          className="h-[4rem] w-full border-none bg-transparent text-[2.1rem] outline-none"
          placeholder="Phone Number"
          value={value || ''}
          onChange={(e) => {
            if (e.target.value.length < 12) {
              onChange(e.target.value);
            }
          }}
        />

        <div className="h-px w-full bg-[#D9D9D9]" />
      </div>
    </div>
  );
};
