'use client';

import { useEffect, useState } from 'react';

export const usePhoneOTP = () => {
  const [otpDigits, setOtpDigits] = useState<string[]>([]);
  const [codeOtpLoading, setCodeOtpLoading] = useState(false);
  // 30 seconds to resend
  const [timeToResend, setTimeToResend] = useState(30);

  // logic to countdown time to resend
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (timeToResend > 0) {
      timer = setInterval(() => {
        setTimeToResend((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeToResend]);

  return {
    otpDigits,
    setOtpDigits,
    codeOtpLoading,
    setCodeOtpLoading,
    timeToResend,
    setTimeToResend,
  };
};
